export default {
	stock:'Stock',
    operateBtn: {
        cancelBtn: 'Cancel',
        sureBtn: 'Confirm',
    },
    home: {
        hot_sale: 'Hot sale',
        time_sale: 'Time sale',
        all_discount: 'Discount',
        good_recomment: 'Recomment'
    },
    kind: {},
	empty_word:'No data available at the moment',
    account: {
        welcome_title1: 'Hello. Nice to meet you.',
        welcome_title2: 'You are currently not logged in',
        login: 'Log in',
        regist: 'Register here',
        order_dzf: 'Pending payment',
        order_dfh: 'Pending shipment',
        order_dsh: 'Pending receipt',
        order_ywc: 'Completed',
        my_account: 'My account',
        my_info: 'Personal information',
        address: 'Address',
        feedback: 'Feedback',
        coupon: 'Coupon',
        set: 'Setting',
        language: 'Language',
        quit: 'Quit',
        tips_title1: 'Operation confirm',
        tips_content1: 'Are you sure you want to log out',
        ShoppingCart: 'ShoppingCart',
        order: 'order',
        logOff: 'log off',
        no_order: 'There are currently no orders available',
        logOff_text: 'Account cancelled',
        regist_success: 'successfully registered',
        search_area: 'Please select region',
        select_text: 'Please select'
    },
    car: {
        extra_reduce: 'Extra Reduce',
        delete: 'Reduce',
        add_Wish: 'Add To Wishlist',
        lookFor: "It seems like I'm looking for",
        total: 'Total',
        choose_coupon: 'Choose Coupon',
        save: 'Save',
        mjq: 'Full Discount Coupon',
        use: 'Use',
        settle: 'Settle',
        subtotal: 'Subtotal',
        tips_title1: 'Please add the product for settlement',
        tips_content1: 'Are you sure you want to delete it',
        empty: 'The shopping cart is empty',
		addok:'Added successfully',
		dook:'Operation successful',
    },
    address: {
        addBtn: 'Add New Address',
        area: 'Area',
        address: 'Address Details',
        operate: 'operate',
        edit: 'edit',
        delete: 'delete',
        set_default: 'set as default'
    },
    coupon: {
        full: 'Full',
        reduce: 'Reduce',
        list_all: 'All',
        list_dsy: 'Pending use',
        list_ysy: 'Used already',
        list_ygq: 'Expired',
        empty: 'The data is empty',
        name: 'coupon'
    },
    feedback: {
        explain: 'Text description',
        placeholder1: 'Please enter feedback content',
        image: 'Image',
        btn: 'Submit'
    },
    goodDetail: {
        advantage1: 'High Rated Seller',
        advantage2: 'Cash on Delivery',
        advantage3: 'Secure Transaction',
        evaluation: 'Overall evaluation',
        comment: 'Comment',
        addCar: 'Add to cart',
        wishlist: 'Wishlist',
        showDefault: 'D',
        manage_wishlist: 'Manage my wishlist',
        save: 'Save',
        count: 'Quantity',
        product_amount: 'Product amount',
        continue_shop: 'Continue',
        menu1: 'Summary',
        menu2: 'Explain',
		yunshuxiangqing:'Transport',
		tedian:'characteristic',
		pifa: 'Ladder pricing',
    },
    orderDetail: {
        orderId: 'Order Id',
        delivery_address: 'Delivery Address',
        putAway: 'Put away',
        open: 'Open',
        product_list: 'Product List',
        tips: 'Once packaged, a single item cannot be cancelled from the order',
        create_time: 'Creation time',
        wait_pay: 'Pending payment',
        wait_pay_text: 'Your order is pending payment',
        wait_express: 'Pending shipment',
        wait_express_text: 'Your order is awaiting shipment',
        wait_confirm: 'Pending receipt of goods',
        wait_confirm_text: 'Your order is awaiting receipt',
        finish: 'Completed',
        finish_text: 'Your order has been completed',
        post_comment: 'Post an evaluation',
        copy_success: 'Replicating Success',
        copy_fail: 'copy failed',
        post_success: 'Operation successful',
		endorder:'Confirm receipt',
    },
    orders: {
        placeholder1: 'Please enter order information',
        label1: 'Screen',
        orderStatus1: 'Unknown',
        orderStatus2: 'Pending payment',
        orderStatus3: 'Pending shipment',
        orderStatus4: 'Pending receipt',
        orderStatus5: 'Completed',
		cancelorder:'Do you want to cancel the order',
    },
    profile: {
        profile_info: 'Personal data',
        email: 'Email',
        first_name: 'First name',
        last_name: 'Last name',
        phone_number: 'Phone number',
        tips: 'This can used to login across all noon apps.',
        add: 'Add',
        birthday: 'Birthday',
        gender: 'Gender',
        male: 'Male',
        female: 'Female',
        nationality: 'Nationality',
        selectNationality: 'SelectNationality',
        add_phoneNumber: 'Add phone number',
        tips1: 'Please enter the new phone number',
        tips2: 'New phone number',
        add_phone_button: 'ADD PHONE NUMBER'
    },
    settlement: {
        addaddress: 'Please add the shipping address first',
		address: 'Address',
        door: 'Is it placed at the door',
        success: 'confirmation'
    },
    shopList: {
        num: 'Nums',
        sort: 'Sort'
    },
    wishList: {
        edit_name: 'Edit Name',
        placeholder1: 'Please enter a name',
        tips1: 'Confirm to delete this wish list',
        tips2: 'Confirm to clear this wish list',
    },
    login: {
        desc: "Hala! Let's  start",
        sendTxt: 'The verification code has been sent to',
        login: ' Log in ',
        regist: ' Register here ',
        placeholder1: 'Please enter email or phone number',
        placeholder2: 'Please enter login password',
        placeholder3: 'Please enter email address',
        placeholder4: 'Please enter login password',
        placeholder5: 'Please fill in the password',
        placeholder6: 'Please fill in the verification code',
		emailerror: 'Please enter the correct email address',
        text1: 'Continue',
        text2: 'Log in',
        title1: 'Select Code',
		send:'Send verification code',
		resend:'Resend',
    },
    pageBottom: {
        support_help: 'We are always Here To Help',
        custom: 'CUSTOMER HAPPINESS CENTER',
        email: 'EMAIL SUPPORT',
        shop: 'SHOP ON THE GO',

        Careers: 'Careers',
        Warranty_Policy: 'Warranty Policy',
        Sell_with_us: 'Sell with us',
        Terms_of_Use: 'Terms of Use',
        Privacy_Policy: 'Privacy Policy',
        Consumer_Rights: 'Consumer Rights'
    },
    search: {
        placeholder1: 'What are you looking for?'
    },
    homeTap: 'Home',
    classification: 'classification',

    PersonalInformation: 'Personal Information',

    SelectDate: 'Select Date',
    Tips: 'Tips',
    Whatareyoulookingfor: 'What are you looking for？',
    scoreL: 'score',
    collect: 'collect',
    ProductDetails: 'Product Details',
    Delivery: 'Delivery',
    xieyi: 'User Agreement',
    yinsi: 'Privacy Policy',
    map: {
        placeholder: 'Enter the search address',
        btn: 'Search',
        btn2: 'Confirm address',
        toast1: 'Choose a shipping address',
        toast2: 'No suitable address found',
        toast3: 'Geographic location encoding failed'
    },
    pages: {
        home: 'Home',
        kind: 'Classification',
        account: 'Account',
        car: 'Car',
        address: 'Address',
        editAdd: 'Edit address',
        feedback: 'Feedback',
        coupon: 'Coupon',
        shopList: 'Product list',
        goodDetail: 'Product details',
        settlement: 'Settlement',
        profile: 'Personal',

        orders: 'Orders',
        orderDetail: 'Order detail',
        map: 'Map',
        wishList: 'Wishlist',
        settlement: 'Settlement'
    },
    editAddress: {
        partTit1: 'Position information',
        lineTit1: 'Area',
        placeholder1: 'Enter shipping address',
        lineTit2: 'Address Details',
        partTit2: 'Personal information',
        lineTit3: 'Last name',
        placeholder2: 'Enter last name',
        lineTit4: 'Name',
        placeholder3: 'Enter first name',
        lineTit5: 'Contact number',
        placeholder4: 'Enter your phone number',
		lineTit6: 'Company',
		placeholder6: 'Enter your Company',
		lineTit7: 'VAT NO',
		placeholder7: 'Enter your VAT NO',
        set: 'Set as default address',
        save: 'Save Information',
		add: 'Add shipping address',
        toast1: 'Incomplete address information',
        toast2: 'Incomplete name filling',
        toast3: 'Provide your phone number',
        toast4: 'Information saved'
    }
};
