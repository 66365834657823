import { baseUrl } from '@/api/http.js'
import axios from '../api/config'
const API_BASE = baseUrl
// const API_BASE = '' //Vue.config.js 里跨域处理所需要地址

export default {
  // 首页-轮播
  // banner_list(data) {
  //   return axios.post(`${API_BASE}/api/Index/banner_list`, data)
  // },
  config_info(data) {
    return axios.post(`${API_BASE}/site/config`, data)
  },
  banner_list(data) {
    return axios.post(`${API_BASE}/index/home`, data)
  },
  slides(data) {
    return axios.post(`${API_BASE}/index/slides`, data)
  },
  category(data) {
    return axios.post(`${API_BASE}/index/category`, data)
  },
  goodsList(data) {
    return axios.post(`${API_BASE}/goods/goodsList`, data)
  },
  userLogin(data) {
    return axios.post(`${API_BASE}/user/login`, data)
  },
  sendmsg(data) {
    return axios.post(`${API_BASE}/user/sendmsg`, data)
  },
  collectlist(data) {
    return axios.post(`${API_BASE}/user/collectlist`, data)
  },
  collect_goods(data) {
    return axios.post(`${API_BASE}/goods/collect_goods`, data)
  },
  userIndex(data) {
    return axios.post(`${API_BASE}/user/index`, data)
  },
  userChange(data) {
    return axios.post(`${API_BASE}/user/changeinfo`, data)
  },
  goodsInfo(data) {
    return axios.post(`${API_BASE}/goods/goodsInfo`, data)
  },
  goodsSpecs(data) {
    return axios.post(`${API_BASE}/goods/goodsSpecs`, data)
  },
  goodsPrice(data) {
    return axios.post(`${API_BASE}/goods/goodsPrice`, data)
  },
  commentlist(data) {
    return axios.post(`${API_BASE}/goods/commentlist`, data)
  },

  xieyi(data) {
    return axios.post(`${API_BASE}/User/xieyi`, data)
  },
  UserAbout(data) {
    return axios.post(`${API_BASE}/User/about`, data)
  },
  orderlist(data) {
    return axios.post(`${API_BASE}/order/orderlist`, data)
  },
  cartIndex(data) {
    return axios.post(`${API_BASE}/cart/index`, data)
  },
  changeNum(data) {
    return axios.post(`${API_BASE}/cart/changeNum`, data)
  },
  confirmorder(data) {
    return axios.post(`${API_BASE}/cart/confirmorder`, data)
  },
  couponlist(data) {
    return axios.post(`${API_BASE}/user/couponlist`, data)
  },
  addorder(data) {
    return axios.post(`${API_BASE}/cart/addorder`, data)
  },
  addcart(data) {
    return axios.post(`${API_BASE}/cart/addcart`, data)
  },
  deletecart(data) {
    return axios.post(`${API_BASE}/cart/deletecart`, data)
  },
  deluser(data) {
    return axios.post(`${API_BASE}/User/deluser`, data)
  },
  orderinfo(data) {
    return axios.post(`${API_BASE}/order/orderinfo`, data)
  },
  orderconfirm(data) {
    return axios.post(`${API_BASE}/order/orderconfirm`, data)
  },
  reg(data) {
    return axios.post(`${API_BASE}/user/reg`, data)
  },
  addressList(data) {
    return axios.post(`${API_BASE}/user/address_list`, data)
  },
  addressAdd(data) {
    return axios.post(`${API_BASE}/user/add_address`, data)
  },
  addressEdit(data) {
    return axios.post(`${API_BASE}/user/edit_address`, data)
  },
  set_default(data) {
    return axios.post(`${API_BASE}/user/edit_address`, data)
  },
  addressDelete(data) {
    return axios.post(`${API_BASE}/user/del_address`, data)
  },
  is_set_default(data) {
    return axios.post(`${API_BASE}/user/set_default`, data)
  },






  about(data) {
    return axios.get(`${API_BASE}/site/about`, data)
  },
  send(data) {
    return axios.post(`${API_BASE}/sms/smsCode`, data)
  },

  login(data) {
    return axios.post(`${API_BASE}/login/account`, data)
  },
  goodsLists(data) {
    return axios.get(`${API_BASE}/goods/lists`, { params: data })
  },
  favoriteLists(data) {
    return axios.get(`${API_BASE}/favorite/lists`, { params: data })
  },
  ordersLists(data) {
    return axios.get(`${API_BASE}/orders/lists`, { params: data })
  },
  ordersDetail(data) {
    return axios.get(`${API_BASE}/orders/detail`, { params: data })
  },
  orders_Delete(data) {
    return axios.post(`${API_BASE}/orders/delete`, data)
  },
  ordersCancel(data) {
    return axios.post(`${API_BASE}/orders/cancel`, data)
  },
  detail(data) {
    return axios.get(`${API_BASE}/goods/detail`, { params: data })
  },
  favorite(data) {
    return axios.post(`${API_BASE}/favorite/add`, data)
  },
  cartLists(data) {
    return axios.get(`${API_BASE}/cart/lists`, { params: data })
  },

  addressDetail(data) {
    return axios.get(`${API_BASE}/address/detail`, { params: data })
  },


  addressSetDefault(data) {
    return axios.post(`${API_BASE}/address/setDefault`, data)
  },
  applyAdd(data) {
    return axios.post(`${API_BASE}/apply/add`, data)
  },
  userCenter(data) {
    return axios.post(`${API_BASE}/user/center`, data)
  },
  confirmOrder(data) {
    return axios.post(`${API_BASE}/orders/confirmOrder`, data)
  },
  couponLists(data) {
    return axios.get(`${API_BASE}/coupon/lists`, { params: data })
  },
  createOrder(data) {
    return axios.post(`${API_BASE}/orders/createOrder`, data)
  },
  prepay(data) {
    return axios.post(`${API_BASE}/pay/prepay`, data)
  },
  cartDelete(data) {
    return axios.post(`${API_BASE}/cart/delete`, data)
  },


  getLoginBg(data) {
    return axios.post(`${API_BASE}/User/getimage`, data)
  },
}
