import Vue from 'vue';
import VueRouter from 'vue-router';
Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'nav',
        component: (resolve) => require(['@/components/nav'], resolve),
        children: [
            { path: '/', name: 'index', meta: { title: 'Home' }, component: (resolve) => require(['@/views/index'], resolve) }, //首页
			{ path: '/image', name: 'image', meta: { title: 'Home' }, component: (resolve) => require(['@/views/image'], resolve) }, //首页
            { path: '/register', name: 'register', meta: { title: 'Regist' }, component: (resolve) => require(['@/views/register'], resolve) }, //注册
            { path: '/goodDetails', name: 'goodDetails', meta: { title: 'Detail' }, component: (resolve) => require(['@/views/goodDetails'], resolve) }, //
            {
                path: '/orderDetails',
                name: 'orderDetails',
                meta: { title: 'Order detail' },
                component: (resolve) => require(['@/views/orderDetails'], resolve)
            }, //

            { path: '/personal', name: 'personal', meta: { title: 'Product list' }, component: (resolve) => require(['@/views/user/personal'], resolve) }, //个人中心
            {
                path: '/personal2',
                name: 'personal2',
                meta: { title: 'Search result' },
                component: (resolve) => require(['@/views/user/personal2'], resolve)
            }, //
            { path: '/wishList', name: 'wishList', meta: { title: 'Wish List' }, component: (resolve) => require(['@/views/user/wishList'], resolve) }, //收藏
            {
                path: '/userComponents',
                name: 'userComponents',
                meta: { title: 'Product' },
                component: (resolve) => require(['@/views/user/index'], resolve),
                children: [
                    {
                        path: '/agreement',
                        name: 'agreement',
                        meta: { title: 'Agreement' },
                        component: (resolve) => require(['@/views/agreement'], resolve)
                    }, //协议
                    {
                        path: '/information',
                        name: 'information',
                        meta: { title: 'Personal Information' },
                        component: (resolve) => require(['@/views/user/information'], resolve)
                    }, //信息
                    {
                        path: '/curriculum',
                        name: 'curriculum',
                        meta: { title: 'Order' },
                        component: (resolve) => require(['@/views/user/curriculum'], resolve)
                    }, //订单
                    {
                        path: '/examination',
                        name: 'examination',
                        meta: { title: 'Shopping Cart' },
                        component: (resolve) => require(['@/views/user/examination'], resolve)
                    }, //
                    {
                        path: '/settlement',
                        name: 'settlement',
                        meta: { title: 'Confirm the order' },
                        component: (resolve) => require(['@/views/user/settlement'], resolve)
                    }, //结算
                    {
                        path: '/preferential',
                        name: 'preferential',
                        meta: { title: 'Coupon' },
                        component: (resolve) => require(['@/views/user/preferential'], resolve)
                    }, //
                    {
                        path: '/Address',
                        name: 'Address',
                        meta: { title: 'Shipping address' },
                        component: (resolve) => require(['@/views/user/Address'], resolve)
                    },
                    {
                        path: '/editAdd',
                        name: 'editAdd',
                        meta: { title: 'Shipping address' },
                        component: (resolve) => require(['@/views/user/editAdd'], resolve)
                    }
                ]
            } //用户个人中心
        ]
    }
];
const routerPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return routerPush.call(this, location).catch((error) => error);
};
// history hash
const router = new VueRouter({
    // mode: 'history',
    base: process.env.BASE_URL,
    routes
});
router.beforeEach((to, from, next) => {
    window.scrollTo(0, 0);

    if (to.meta.title) {
        window.document.title = to.meta.title;
    } else {
        next();
    }

    console.log(to);
    // 判断是否登录
    if (
        to.path == '/' ||
        to.path == '/Journalism' ||
        to.path == '/login' ||
        to.path == '/retrieve' ||
        to.path == '/register' ||
        to.path == '/course' ||
        to.path == '/courseList' ||
        to.path == '/courseListSearch' ||
        to.path == '/JournalismDetails'
    ) {
        next();
    } else if (window.localStorage.getItem('jx-token')) {
        next();
    } else {
        next();
        // next({
        //   name: 'login',
        // })
        // gtMessage.info('请先登录')
    }
});

export default router;
